/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import jwtDecode from 'jwt-decode'
import RolesEnum from '../utils/interfaces/RolesEnum'

/* eslint-disable no-script-url */
function MenuLeft() {
  const [path, setPath] = React.useState('')

  React.useEffect(() => {
    setPath(window.location.pathname)
  }, [])

  const token = sessionStorage.getItem('token')
  let roles = [0]
  if (token) {
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
  }
  return (
    <>
      {/* <!--sidebar wrapper --> */}
      <div className='sidebar-wrapper' data-simplebar='true'>
        <div
          className='sidebar-header d-flex justify-content-center p-0'
          style={{ padding: 0, height: '165px', width: '102%' }}
        >
          <div style={{ width: '90%' }}>
            <img
              src='/logo.webp'
              style={{ width: '90%' }}
              className='w-100 bg-white rounded'
              alt='logo icon'
              />
          </div>
          {/* <div className="toggle-icon ms-auto">
						<i className="bx bx-arrow-to-left"></i>
            </div> */}
        </div>
        <ul className='metismenu' style={{ marginTop: '170px' }} id='menu'>
          {token && (
            <>
              {
                (!roles.includes(RolesEnum.Docente) &&
                !roles.includes(RolesEnum.CoordinadorCentro) &&
                !roles.includes(RolesEnum.ResponsableAlmacen)) &&
                  <ButtonNav title='Inicio' path='/' icon='bx-home-circle' status={path === '/'} />
              }
              {
                (roles.includes(RolesEnum.Docente) || roles.includes(RolesEnum.Admin)) &&
                  <ButtonNav title="Reservas" path="/reservas" icon='bx-calendar-plus' status={path === '/reservas'} />
              }
              {
                roles.includes(RolesEnum.CoordinadorCentro) &&
                  <ButtonNav title='Inicio' path='/' icon='bx-home-circle' status={path === '/'} />
              }
              {
                ( roles.includes(RolesEnum.CoordinadorCentro) || roles.includes(RolesEnum.Admin) ) && 
                  <ButtonNav title='Usuarios' path='/usuarios' icon='bx-user-circle' status={path === '/usuarios'} />                
                
              }
              {
                roles.includes(RolesEnum.ResponsableAlmacen) &&
                  <>
                    <ButtonNav title='Inicio' path='/' icon='bx-home-circle' status={path === '/'} />
                    <ButtonNav title='Subir Recursos' path='/subirrecursos' icon='bx-bus-school' status={path === '/subirrecursos'} />
                    <ButtonNav title='Almacenes' path='/almacenes' icon='bx-store' status={path === '/almacenes'} />
                    <ButtonNav title='Recursos' path='/recursos' icon='bx-data' status={path === '/recursos'} />
                  </>
              }
              {
                roles.includes(RolesEnum.Admin) &&
                  <>
                    <ButtonNav title='Centros' path='/centros' icon='bx-building' status={path === '/centros'} />
                    <ButtonNav title='Almacenes' path='/almacenes' icon='bx-store' status={path === '/almacenes'} />
                    <ButtonNav title='Subir Recursos' path='/subirrecursos' icon='bx-bus-school' status={path === '/subirrecursos'} />
                    <ButtonNav title='Recursos' path='/recursos' icon='bx-data' status={path === '/recursos'} />
                  </>
              }
              {
                roles.includes(RolesEnum.CoordinadorCentro) &&
                  <>
                    <ButtonNav title='Centros' path='/centros' icon='bx-building' status={path === '/centros'} />
                    <ButtonNav title='Reservas' path='/reservas' icon='bx-calendar-plus' status={path === '/reservas'} />
                  </>
              }
            </>
          )}

          {
            roles.includes(RolesEnum.Admin) &&
              <ButtonNav title='Configurador' path='/configurador' icon='bx-cog' status={path === '/configurador'} />
          }
        </ul>
      </div>
    </>
  )
}

export default MenuLeft

const ButtonNav = ({ title, path, icon, status }: { title: string; path: string; icon:string; status:boolean }) => {
  return (
    <li className={`rounded ${status ? 'bg-white shadow-xs' : 'text-white'}`}>
      <a href={path}>
        <div className="parent-icon">
          <i 
            className={`bx ${icon}`} 
            style={{ color: status ? '#A70606' : '#fff' }}
          />
        </div>
        <div 
          className="menu-title" 
          style={{ color: status ? '#A70606' : '#fff' }}
        >
          {title}
        </div>
      </a>
    </li>
  )
}
