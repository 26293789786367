import { Column, Table as ReactTable } from '@tanstack/react-table'
import { Select } from 'native-base'

function Filter({
  column,
  table,
}: {
  column: Column<any, any>
  table: ReactTable<any>
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()
  const getReservaName = (id: any) =>{
    switch (id) {
    case 1:
      return 'Pendiente'
    case 2:
      return 'Aprobado'
    case 3:
      return 'Rechazado'
    case 4:
      return 'Finalizado'
    default:
      break;
  }
  }
  return typeof firstValue === 'number' ? (
    <div className='flex space-x-2'
      style={{backgroundColor: '#fff2a7' }}
    >
    {column.id === 'Estado' && (
  <Select selectedValue={(getReservaName(columnFilterValue as [number, number])?.[0])} onValueChange={(e) => column.setFilterValue((old: [number, number]) => [
            e,
            e,
          ])} backgroundColor={'#fff2a7'}>
    <Select.Item  label='Todos' value='' />
    <Select.Item  label='Pendiente' value={'1'} />
    <Select.Item  label='Aprobado' value={'2'} />
    <Select.Item  label='Rechazado' value={'3'} />
    <Select.Item  label='Finalizado' value={'4'} />
  </Select>
  )}
      {/* <input
        type='number'
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            e.target.value,
            old?.[1],
          ])
        }
        style={{ width: '50%' }}
        placeholder={`Min`}
        className=' border shadow rounded'
      />
      
      <input
        type='number'
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            old?.[0],
            e.target.value,
          ])
        }
        style={{ width: '50%' }}
        placeholder={`Max`}
        className=' border shadow rounded'
      /> */}
    </div>
  ) : (
  <>
    {column.id === 'Estado' ? (
  <Select selectedValue={(columnFilterValue ?? '') as string} onValueChange={(e) => column.setFilterValue(e)} backgroundColor={'white'}>
    <Select.Item disabled label='' value='' />
    <Select.Item label='Pendiente' value={'1'} />
    <Select.Item label='Aprobado' value={'2'} />
    <Select.Item label='Rechazado' value={'3'} />
    <Select.Item label='Finalizado' value={'4'} />
  </Select>
  ): (
  <input
      type='text'
      value={(columnFilterValue ?? '') as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Buscar...`}
      style={{ width: '100%', paddingLeft: 8, paddingRight: 8, paddingTop: 4, paddingBottom: 2, marginTop: 2, backgroundColor: '#fff2a7' }}
      className='w-36 border shadow rounded'
    />
  )}
  </>
    
  )
}

export default Filter
