/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import axios from 'axios'
import { Box, Button, Image, Input, Select } from 'native-base'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Wrapper from '../../components/Wrapper'
import { API_URL } from '../../utils/env'
import Modal from '../../components/Modal/Modal'
import ShopCart from '../../utils/Persistence/ShopCart'
import getStatusRecurso from './utils/getStatusRecurso'
import EstadosRecursoEnum from '../../utils/tools/EstadosRecursoEnum'
import { Linking } from 'react-native'
import { formatDate } from '../../utils/format'
import { CSVLink } from 'react-csv'
// import { useExcelDownloder } from 'react-xls'

function Incidencias() {
  const navigate = useNavigate()
  const [incidencias, setIncidencias] = useState([])
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const params = useParams()
  // const { ExcelDownloder, Type } = useExcelDownloder()
  const xlsData = {
    document: [
      ...incidencias.map((incidencia: any) => {
        return {
          id: incidencia.id,
          description: incidencia.description,
          created_at: formatDate(new Date(incidencia.created_at)),
          userCreated:
            `${incidencia.userCreated?.username} ${incidencia.userCreated?.name}` ||
            'N/A',
          finalized: incidencia.finalized ? 'SI' : 'NO',
          userFinalized:
            `${incidencia.userFinalized?.username} ${incidencia.userFinalized?.name}` ||
            'N/A',
        }
      }),
    ],
  }
  const csvData = [
    ['Id', 'Descripción', 'Fecha', 'Creada por', 'Resuelta', 'Resuelta por'],
    ...incidencias.map(
      ({
        id,
        description,
        userCreated,
        created_at,
        finalized,
        userFinalized,
      }: any) => [
        id,
        description,
        formatDate(new Date(created_at)),
        `${userCreated?.username} ${userCreated?.name}` || 'N/A',
        finalized ? 'SI' : 'NO',
        `${userFinalized?.username} ${userFinalized?.name}` || 'N/A',
      ]
    ),
  ]
  const [form, setForm] = useState<{
    name: any
    fabricator: any
    model: any
    depot: any
    status: any
    serie: any
    cime: any
  }>({
    name: null,
    fabricator: null,
    model: null,
    depot: '0',
    status: '0',
    serie: null,
    cime: null,
  })
  const shopCart = new ShopCart()
  const status = [
    { id: 1, name: 'Disponible' },
    { id: 2, name: 'Reservada' },
    { id: 3, name: 'Incidencia' },
    { id: 4, name: 'Mantenimiento' },
    { id: 5, name: 'En traslado' },
    { id: 6, name: 'Confirmación envio' },
    { id: 8, name: 'Reservada Solicitud de envio' },
    { id: 9, name: 'Dado de baja' },
  ]
  const getIncidencias = () => {
    axios
      .get(`${API_URL}/resource-issue/all/${params.id}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((res) => {
        setIncidencias(res.data)
      })
      .catch((err) => {
        if (__DEV__) {
          console.log(err)
        }
      })
  }
  const goToLogs = () => {
    navigate(`/logsincidencias/exportar/${params.id}`)
  }

  useEffect(() => {
    getIncidencias()
  }, [])
  return (
    <Wrapper title='Incidencia' subtitle=''>
      {/* <!--start page wrapper --> */}
      <div className='page-wrapper' style={{ height: '100vh' }}>
        <div className='page-content'>
          <div className='row'>
            <div className='col-xl-12 mx-auto'>
              <h6 className='mb-0 text-uppercase'></h6>
              <Box flexDir={'row'} justifyContent={'flex-end'}>
                {/* <Input width={200} mr={4} placeholder={'Buscar...'} /> */}
                {/* <div className='col-sm-2 me-2'>
                  <Button onPress={() => goToLogs()} colorScheme={'info'}>
                    Exportar Logs Incidencias
                  </Button>
                </div> */}
                <div className='col-sm-2'>
                  <Button
                    onPress={() => navigate(`/crearincidencias/${params.id}`)}
                    style={{
                      backgroundColor: '#0d6efd',
                      borderRadius: 4,
                      padding: 8,
                      paddingLeft: 32,
                      paddingRight: 32,
                    }}
                    size={'md'}
                    _text={{
                      color: '#fff',
                      fontWeight: 'bold',
                      fontSize: 16,
                      marginBottom: 1,
                    }}
                  >
                    Nueva Incidencia
                  </Button>
                </div>
              </Box>
              <hr />
              <div className='card'>
                <div className='card-body'>
                  {/* <h4 className='card-title text-primary'>Buscador avanzado</h4> */}
                  <div className='align-content-end justify-content-end w-100'>
                    <div className='d-flex justify-content-end'>
                      <CSVLink
                        className='downloadbtn'
                        filename='incidencias.csv'
                        data={csvData}
                      >
                        Exportar a CSV
                      </CSVLink>
                    </div>
                    {/* <div className='d-flex justify-content-end'>
                      <CSVLink
                        className='downloadbtn'
                        filename='incidencias.csv'
                        data={csvData}
                      >
                        Exportar a CSV
                      </CSVLink>
                    </div> */}
                  </div>
                  <div className='table-responsive mt-4'>
                    <table className='table table-responsive'>
                      <thead className='table-dark'>
                        <tr>
                          <th>Id</th>
                          <th>Descripción</th>
                          <th>Fecha</th>
                          <th>Creada por</th>
                          <th>Resuelta</th>
                          <th>Resuelta por</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {incidencias.map((incidencia: any) => (
                          <tr>
                            <td>{incidencia.id}</td>
                            <td>{incidencia.description}</td>
                            <td>
                              {formatDate(new Date(incidencia.created_at))}
                            </td>
                            <td>
                              {incidencia.userCreated?.username}{' '}
                              {incidencia.userCreated?.name}
                            </td>
                            <td>{incidencia.finalized ? 'Si' : 'No'}</td>
                            <td>
                              {incidencia.userFinalized?.username}{' '}
                              {incidencia.userFinalized?.name}
                            </td>
                            <td>
                              <Button
                                // maxWidth={140}
                                p={2}
                                colorScheme={`warning`}
                                onPress={async () => {
                                  navigate(`/incidencias/ver/${incidencia.id}/${params.id}`)
                                }}
                                size={'xs'}
                                className='btn btn-xs '
                                style={{
                                  borderRadius: 4,
                                  padding: 4,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                }}
                                _text={{
                                  color: '#fff',
                                  fontWeight: 'bold',
                                  fontSize: 16,
                                  marginBottom: 1,
                                }}
                              >
                                Ver
                              </Button>
                            </td>
                            <td></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--end page wrapper --> */}
    </Wrapper>
  )
}

export default Incidencias
