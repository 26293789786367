import { Button, Input, Select } from "native-base";
import Wrapper from "../../components/Wrapper";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { API_URL } from "../../utils/env";
import { baseApi } from "../../utils/api";
function CrearUsuario() {
	const [form, setForm] = useState({
		username: "",
		password: "",
		email: "",
		active: true,
		phone: "",
		name: "",
		dni: "",
		roles:[2]
	});
	const [image, setImage] = useState<any>(null);
	const [retypePassword, setRetypePassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const createUser = async () => {
		if (form.password !== retypePassword) {
			alert("Las contraseñas no coinciden");
			return;
		}
		try {
			if(form.name === '' || form.username === '' || form.password === ''){
				alert("Los campos nombre, usuario y contraseña son obligatorios (Nombres, Apellidos, Contraseña)");
				return false;
			}
			setIsLoading(true)
			var newUser = new FormData();
			newUser.append("username", form.username);
			newUser.append("password", form.password);
			newUser.append("email", form.email);
			newUser.append("active", form.active as unknown as string);
			newUser.append("phone", form.phone);
			newUser.append("name", form.name);
			newUser.append("dni", form.dni);
			newUser.append("roles", JSON.stringify(form.roles));
			if(image !== null){
				newUser.append("image", image!, image ? image.name : "");
			}

			const { data } = await baseApi.post(`${API_URL}/auth/register`, newUser, {
				headers: {
					"Content-Type": "multipart/form-data",
					"Authorization": "Bearer " + sessionStorage.getItem("token")
				}
			});
			navigate('/usuarios');
		} catch (error) {
			if(__DEV__){
				  console.log(error);
			  }
		}
		setIsLoading(false)
	}
	return (
		<Wrapper title="Crear Usuario" subtitle="">
			<div className="page-wrapper">
				<div className="page-content">
					<div className="row">
						<div className="col-xl-12 mx-auto">
							<hr />
							<div className="card">
								<div className="card-body">
									<form className="row g-3">
										<div className="col-md-4 mb-3">
											<label className="form-label fw-bold">Nombres:</label>
											<Input
												value={form.username}
												onChangeText={(text) => setForm({ ...form, username: text })}
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-4 mb-3">
											<label className="form-label fw-bold">Apellidos:</label>
											<Input
											value={form.name}
											onChangeText={(text) => setForm({ ...form, name: text })}
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-4 mb-3">
											<label className="form-label fw-bold">DNI:</label>
											<Input
											value={form.dni}
											onChangeText={(text) => setForm({ ...form, dni: text })}
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-6 mb-3">
											<label className="form-label fw-bold">Email:</label>
											<Input
											value={form.email}
											onChangeText={(text) => setForm({ ...form, email: text })}
												type="email"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-3 mb-3">
											<label className="form-label fw-bold">Telefono:</label>
											<Input
											value={form.phone}
											onChangeText={(text) => setForm({ ...form, phone: text })}
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-3 mb-3">
											<label className="form-label fw-bold">Foto del Usuario:</label>

											<input
												onChange={(event: any) =>
													setImage(event.target.files[0])
												}
												type="file"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-6 mb-3">
											<label className="form-label fw-bold">Contraseña:</label>
											<Input
												value={form.password}
												onChangeText={(text) => setForm({ ...form, password: text })}
												type="password"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-6 mb-3">
											<label className="form-label fw-bold">Repetir Contraseña:</label>
											<Input
											value={retypePassword}
											onChangeText={(text) => setRetypePassword(text)}
												type="password"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-6 mb-3">
											<label htmlFor="estado" className="form-label fw-bold">
												Estado
											</label>
											<Select nativeID="estado" onValueChange={(value)=> setForm({...form, active: value as unknown as boolean})} selectedValue={form.active.toString()}>
												<Select.Item value="" label="Escoger..."/>
												<Select.Item value="true" label="Activo"/>
												<Select.Item value="false" label="No Activo"/>
											</Select>
										</div>
										<div className="col-md-6 mb-3">
											<label htmlFor="sadmin" className="form-label fw-bold">
												Super Administrador
											</label>
											<Select nativeID="sadmin" onValueChange={(value)=> setForm({...form, roles: [parseInt(value)]})} selectedValue={form.roles[0].toString()}>
												<Select.Item value="" label="Escoger..."/>
												<Select.Item value="2" label="No"/>
												<Select.Item value="1" label="Si"/>
											</Select>
										</div>
										<div className="col-md-12 mb-3 justify-content-center d-flex">
											<Button 
											style={{marginRight: 10,
												paddingHorizontal: 20,
												paddingVertical: 10,
												borderRadius: 8,
												backgroundColor: '#0d6efd',
												width:'50%'
											  }}
											  _text={{
												color: '#fff',
												fontWeight: 'bold',
												fontSize: 16,
												marginBottom: 1,
											  }}	
											isLoading={isLoading} onPress={()=>createUser()}>Crear Usuario</Button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
}
export default CrearUsuario;
