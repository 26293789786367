const getEstadosReservas = (estado: any) => {
  switch (estado) {
    case 1:
      return <span className='font-16 bg-primary fw-bold text-white px-2 py-1 rounded justify-content-center'>
         <i className='bx bx-hourglass  me-1'></i>
        Pendiente
      </span>
    case 2:
      return <span className='font-16 bg-success fw-bold text-white px-2 py-1 rounded justify-content-center'>
        <i className='bx bx-badge-check  me-1'></i>
        Aprobado
      </span>
    case 3:
      return <span className='font-16 bg-danger fw-bold text-white px-2 py-1 rounded justify-content-center'>
        <i className='bx bx-block  me-1'></i>
        Rechazado
      </span>  
    case 4:
      return <span className='font-16 bg-secondary fw-bold text-white px-2 py-1 rounded justify-content-center'>
        <i className='bx bx-flag  me-1'></i>
        Finalizado
      </span>  
    default:
      break;
  }
}

export default getEstadosReservas