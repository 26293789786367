import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { Box, Button, HStack, Text, VStack } from 'native-base'
import React, { useEffect } from 'react'
import { TouchableOpacity } from 'react-native'
import { useLocation, useNavigate } from 'react-router-dom'
import { API_URL } from '../../utils/env'
import { getFormatedDate } from '../../screens/Recursos/utils/getFormatedDate'
import typeNotificationConstant from '../../screens/Recursos/utils/TypeNotificationConstant'

function Header({ hasButtonRight, title, subtitle }: { hasButtonRight?: boolean, title?: string, subtitle?: string }) {
  const location = useLocation()
  const navigate = useNavigate()
  const token = sessionStorage.getItem('token')
  const [username, setUsername] = React.useState<string>('')
  const [notifications, setNotifications] = React.useState<any>(null)

  const getNotifications = async () => {
    const res = await axios.get(`${API_URL}/user/get/notifications`, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      },
    })
    setNotifications(res.data)
  }
  const readNotifications = async (id: any) => {
    const res = await axios.get(`${API_URL}/user/read/notification/${id}`, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      },
    })
  }
  useEffect(() => {
    if (token) {
      const { userData }: any = jwtDecode(token as string)
      setUsername(userData.username + ' ' + userData.name)
    }
  }, [token])
  useEffect(() => {
    if (token) {
      getNotifications()
    }
  }, [])
  return (
    <>
      <header>
        <div className='topbar d-flex align-items-center'>
          <nav className='navbar navbar-expand'>
            <div
              className='mobile-toggle-menu pr-4'
              style={{ paddingRight: '8px' }}
            >
              <i className='bx bx-menu'></i>
            </div>

            {token && (
              <div className='dropdown' style={{ width: '70%' }}>
                <HStack alignItems='center' width={'100%'} space={3}>
                  {location.pathname !== '/' && (
                    <div>
                      <TouchableOpacity
                        onPress={() => {
                          window.history.back()
                        }}
                      >
                        <i className='bx bx-arrow-back font-24 text-danger px-2 rounded color-primary'></i>
                      </TouchableOpacity>
                    </div>
                  )}
                  <VStack style={{ width: '100%' }}>
                    <Text
                      fontSize={24}
                      fontWeight={600}
                      color={'red.900'}
                      _dark={{
                        color: 'primary.500',
                      }}
                      lineBreakMode='tail'
                      width={'100%'}
                      flexWrap={'wrap'}
                    >
                      {title ?? 'Resumen'}
                    </Text>
                    <Text
                      fontSize={18}
                      color='coolGray.600'
                      _dark={{
                        color: 'warmGray.200',
                      }}
                      lineBreakMode='tail'
                    >
                      {subtitle ?? 'FLCA'}
                    </Text>
                  </VStack>
                </HStack>
              </div>
            )}
            {hasButtonRight && (
              <div className='search-bar flex-grow-1'>
                <Box flexDir={'row'}>
                  <div
                    className='position-relative search-bar-box'
                    style={{ width: '100%', paddingRight: '5px' }}
                  >
                    <input
                      disabled
                      type='text'
                      className='form-control search-control'
                      placeholder='Todas las Provincias'
                    />{' '}
                    <span className='position-absolute top-50 search-show translate-middle-y'>
                      <i className='bx bx-search'></i>
                    </span>
                    <span className='position-absolute top-50 search-close translate-middle-y'>
                      <i className='bx bx-x'></i>
                    </span>
                  </div>
                  <Button>Buscar </Button>
                </Box>
              </div>
            )}
            <div className='top-menu ms-auto'>
              <ul className='navbar-nav align-items-center'>
                <li className='nav-item dropdown dropdown-large'>
                  {!token && (
                    <a className='nav-link' href='/' role='button'>
                      <i className='bx bx-user-circle'></i> Iniciar Sesión
                    </a>
                  )}
                  {token && (
                    <a
                      className='nav-link dropdown-toggle dropdown-toggle-nocaret position-relative'
                      href='#'
                      role='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      {notifications?.unread > 0 ? (
                        <span className='alert-count'>
                          {notifications?.unread}
                        </span>
                      ) : null}
                      <i className='bx bx-bell'></i>
                    </a>
                  )}
                  <div
                    className='dropdown-menu dropdown-menu-end'
                    data-bs-popper='static'
                  >
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <div className='msg-header'>
                        <p className='msg-header-title'>Notificaciones</p>
                        {/* <p className="msg-header-clear ms-auto">Marks all as read</p> */}
                      </div>
                    </a>
                    <div className='header-notifications-list ps overflow-scroll'>
                      {notifications?.notifications.map((v: any, i: number) => {
                        const fecha = new Date(v.created_at)
                        return (
                          <TouchableOpacity
                            key={i}
                            onPress={async () => {
                              await readNotifications(v.id)
                              await getNotifications()
                              navigate(
                                `${typeNotificationConstant[v.type].to}${
                                  v.entity_id
                                }`
                              )
                            }}
                          >
                            <div className='dropdown-item '>
                              <div className='d-flex align-items-center'>
                                <div
                                  className={`notify ${
                                    typeNotificationConstant[v.type].color
                                  }`}
                                >
                                  <i
                                    className={`bx ${
                                      typeNotificationConstant[v.type].icon
                                    }`}
                                  ></i>
                                </div>
                                <div className='flex-grow-1'>
                                  <h6 className='msg-name'>
                                    {typeNotificationConstant[v.type].title}
                                    <span className='msg-time float-end'>
                                      {getFormatedDate(fecha)}
                                    </span>
                                  </h6>
                                  <p className='msg-info'>
                                    {v.read ? 'Leído' : 'No Leído'}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </TouchableOpacity>
                        )
                      })}
                      <div className='ps__rail-x'>
                        <div className='ps__thumb-x' tabIndex={0}></div>
                      </div>
                      <div className='ps__rail-y'>
                        <div className='ps__thumb-y' tabIndex={0}></div>
                      </div>
                    </div>
                    {/* <a >
                      <div className='text-center msg-footer'>
                        Ver todas las notificaciones
                      </div>
                    </a> */}
                  </div>
                </li>
                <li className='nav-item dropdown dropdown-large bg-main rounded my-2'>
                  {token && (
                    <a
                      className='nav-link dropdown-toggle dropdown-toggle-nocaret text-white'
                      href='#'
                      role='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <i className='bx bx-user bg-white px-2 rounded color-primary mx-2'></i> {token && username}
                    </a>
                  )}
                  <div className='dropdown-menu dropdown-menu-end' 
                    style={{ backgroundColor: '#fff', borderColor: '#A70606', borderWidth: '2px' }}
                  >
                    <div className='row row-cols-3 g-3 p-3'>
                      <TouchableOpacity
                        onPress={() => {
                          navigate('/editarmisusario')
                        }}
                      >
                        <div className='col text-center'>
                          <div className='app-box mx-auto bg-gradient-burning text-white'>
                            <i className='bx bx-user'></i>
                          </div>
                          <div className='app-title'>Perfil</div>
                        </div>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          sessionStorage.removeItem('token')
                          document.location.href = '/'
                        }}
                      >
                        <div className='col text-center'>
                          <div className='app-box mx-auto bg-gradient-cosmic text-white'>
                            <i className='bx bx-window-close'></i>
                          </div>
                          <div className='app-title'>Cerrar Sesión</div>
                        </div>
                      </TouchableOpacity>
                      
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      {/* <!--end header --> */}
    </>
  )
}
export default Header
