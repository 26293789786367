/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import axios from 'axios'
import { Box, Button, Image, Input, Select } from 'native-base'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Wrapper from '../../components/Wrapper'
import { API_URL } from '../../utils/env'
import Modal from '../../components/Modal/Modal'
import ShopCart from '../../utils/Persistence/ShopCart'
import getStatusRecurso from './utils/getStatusRecurso'
import EstadosRecursoEnum from '../../utils/tools/EstadosRecursoEnum'
import { Linking } from 'react-native'
import { formatDate } from '../../utils/format'
import RolesEnum from '../../utils/interfaces/RolesEnum'
import jwtDecode from 'jwt-decode'

function VerIncidencias() {
  const navigate = useNavigate()
  const [incidencias, setIncidencias] = useState<any>([])
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [resolves, setResolves] = useState<any>(false)
  const params = useParams()
  const user: any = jwtDecode(sessionStorage.getItem('token') as string)
  const token = sessionStorage.getItem('token')

  let roles = [0]
  if (token) {
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
  }
  const [form, setForm] = useState<{
    name: any
    fabricator: any
    model: any
    depot: any
    status: any
    serie: any
    cime: any
  }>({
    name: null,
    fabricator: null,
    model: null,
    depot: '0',
    status: '0',
    serie: null,
    cime: null,
  })
  const shopCart = new ShopCart()
  const status = [
    { id: 1, name: 'Disponible' },
    { id: 2, name: 'Reservada' },
    { id: 3, name: 'Incidencia' },
    { id: 4, name: 'Mantenimiento' },
    { id: 5, name: 'En traslado' },
    { id: 6, name: 'Confirmación envio' },
    { id: 8, name: 'Reservada Solicitud de envio' },
    { id: 9, name: 'Dado de baja' },
  ]
  const setResolve = (resolve: boolean) => {
    axios
      .put(
        `${API_URL}/resource-issue/update/${params.id}`,
        {
          finalized: resolve,
        },
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          },
        }
      )
      .then((res) => {
        

        window.location.reload()
      })
      .catch((err) => {
        
          console.log(err)
        
      })
  }

  const validation = (id:string) => {
    axios
    .get(`${API_URL}/reservation/resource/${id}/approved-reservation`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
    .then((res) => {
      
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const getIncidencias = () => {
    axios
      .get(`${API_URL}/resource-issue/${params.id}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((res) => {
        setIncidencias(res.data)
      })
      .catch((err) => {
        if (__DEV__) {
          console.log(err)
        }
      })
  }

  useEffect(() => {
    getIncidencias()
  }, [])
  return (
    <Wrapper title='Ver Incidencia' subtitle=''>
      {/* <!--start page wrapper --> */}
      <div className='page-wrapper' style={{ height: '100vh' }}>
        <div className='page-content'>
          <div className='row'>
            <div className='col-xl-12 mx-auto'>
              <h6 className='mb-0 text-uppercase'></h6>
              {/* <Box flexDir={'row'} justifyContent={'flex-end'}> */}
              {/* <Input width={200} mr={4} placeholder={'Buscar...'} /> */}
              {/* <Button onPress={() => navigate('/cesta')}>
                  Nueva Incidencia
                </Button>
              </Box> */}
              <hr />
              <div className='card'>
                <div className='card-body'>
                  {/* <h4 className='card-title text-primary'>Buscador avanzado</h4> */}

                  <table className='table table-responsive'>
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Fecha</th>
                        <th>Resuelta</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {[incidencias].map((incidencia: any) => (
                        <tr>
                          <td>{incidencia.id}</td>
                          <td>{formatDate(new Date(incidencia.created_at))}</td>
                          <td>{incidencia.finalized ? 'Si' : 'No'}</td>
                          <td>
                            {!roles.includes(RolesEnum.Docente) && (
                              <>
                                {!incidencia.finalized && (
                                  <Button
                                    // maxWidth={140}
                                    p={2}
                                    colorScheme={`warning`}
                                    onPress={async () => {
                                      const confirmar =
                                        confirm('¿Estás seguro?')
                                      if (confirmar) {
                                        setResolve(true)
                                      }
                                    }}
                                    size={'xs'}
                                    className='btn btn-xs '
                                  >
                                    Resolver
                                  </Button>
                                )}
                              </>
                            )}
                          </td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div>
                    <h4>Descripción</h4>
                    <span>{incidencias.description}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--end page wrapper --> */}
    </Wrapper>
  )
}

export default VerIncidencias
