/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import axios from 'axios'
import { Avatar, Box, Button, Input } from 'native-base'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Wrapper from '../../components/Wrapper'
import { API_URL } from '../../utils/env'
import { UserInterface } from '../../utils/interfaces/UserInterface'
import { TouchableOpacity } from 'react-native'
import jwtDecode from 'jwt-decode'
import RolesEnum from '../../utils/interfaces/RolesEnum'

function Usuarios() {
  const navigate = useNavigate()
  const [usersList, setUsersList] = useState([])
  const [search, setSearch] = useState('')
  const token = sessionStorage.getItem('token')
  let roles = [0]
  if (token) {
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
  }
  const getUsers = () => {
    axios
      .get(`${API_URL}/user/all?search=${search}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((res) => {
        setUsersList(res.data)
      })
      .catch((err) => {
        if(__DEV__){
				  console.log(err);
			  }
      })
  }
	const deleteUser = (id: number) => {
		axios
			.delete(`${API_URL}/auth/delete/${id}`, {
				headers: {
					Authorization: "Bearer " + sessionStorage.getItem("token"),
				},
			})
			.then((res) => {
				alert("Usuario eliminado correctamente");
				getUsers();
			})
			.catch((err) => {
				if(__DEV__){
				  console.log(err);
			  }
			});
	}




  useEffect(() => {
    getUsers()
  }, [])
  useEffect(() => {
    getUsers()
  }, [search])
  return (
    <Wrapper title='Usuarios' subtitle=''>
      {/* <!--start page wrapper --> */}
      <div className='page-wrapper'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-xl-12 mx-auto'>
              {/* <h6 className="mb-0 text-uppercase"></h6> */}
              <div
                className='d-flex justify-content-end align-items-center'
              >
                <Input
                  onChangeText={(e) => {
                    setSearch(e)
                  }}
                  width={320}
                  mr={4}
                  placeholder={'Buscar...'}
                  style={{ 
                    borderRadius: 4,
                    borderWidth: 2,
                    borderColor: '#000000',
                    padding: 8,
                    paddingLeft: 20,
                    paddingRight: 20,
                    backgroundColor: '#fff2a7',
                   }}
                />
                <Button onPress={() => navigate('/usuarios/crear')}
                  style={{
                    backgroundColor: '#0d6efd',
                    borderRadius: 4,
                    padding: 8,
                    paddingLeft: 32,
                    paddingRight: 32,
                  }}
                  size={'md'}
                  _text={{
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: 16,
                    marginBottom: 1,
                  }}
                  >
                  Crear
                </Button>
              </div>
              <hr />
              <div className='card'>
                <div className='card-body table-responsive'>
                  <table className='table mb-0 table-reponsive'>
                    <thead
                      className='table-dark'  
                      
                    >
                      <tr>
                        <th scope='col'>ID</th>
                        <th scope='col'></th>
                        <th scope='col'>Nombres</th>
                        <th scope='col'>Apellidos</th>
                        <th scope='col'>Email</th>
                        <th scope='col'>S. Admin</th>
                        <th scope='col'>Estado</th>
                        <th scope='col'></th>
                        {roles.includes(RolesEnum.Admin) && (
                          <th scope='col'></th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {usersList.map((user: UserInterface) => (
                        <tr key={user.id}>
                          <td>{user.id}</td>
                          <td>
                            <Avatar
                              size={'sm'}
                              bg='green.500'
                              source={{
                                uri: user.image.url,
                              }}
                            >
                              {user.name.charAt(0)}
                            </Avatar>
                          </td>
                          <td>{user.username}</td>
                          <td>{user.name}</td>
                          <th scope='row'>{user.email}</th>
                          <td>
                            {user.userRole[0].role_id === 1 ? 'SI' : 'NO'}
                          </td>
                          <td>{user.active ? 
                          <p
                            className='bg-success text-white rounded-1 text-center fw-bold pb-1'
                          >Activo</p>
                          : 
                          <p
                            className='bg-danger text-white rounded-1 text-center fw-bold pb-1'
                          >Inactivo</p>}
                          </td>
                          <td>
                            <TouchableOpacity
                              onPress={() =>
                                navigate(`/usuarios/editar/${user.id}`)
                              }
                            >
                              <i className='bx bx-edit font-24 bg-warning text-white rounded-1'
                                style={{
                                  paddingLeft: 16,
                                }}
                              ></i>
                            </TouchableOpacity>
                          </td>
                          {roles.includes(RolesEnum.Admin) && <td>
														<TouchableOpacity onPress={()=>{
															const confirm = window.confirm("¿Está seguro que desea eliminar este usuario?");
															if(confirm){
																deleteUser(user.id)
															}
														}}
                            >
															<i className="bx bx-trash font-24 bg-danger text-white rounded-1"
                                style={{
                                  paddingLeft: 16,
                                }}
                              ></i>
														</TouchableOpacity>
													</td>}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--end page wrapper --> */}
    </Wrapper>
  )
}

export default Usuarios
