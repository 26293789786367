/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import axios from 'axios'
import { Avatar, Box, Button, HStack, Input } from 'native-base'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Wrapper from '../../components/Wrapper'
import { API_URL } from '../../utils/env'
import Modal from '../../components/Modal/Modal'
import jwtDecode from 'jwt-decode'
import RolesEnum from '../../utils/interfaces/RolesEnum'
import { formatDate } from '../../utils/format'
import EstadosReservasEnum from '../../utils/interfaces/EstadosReservasEnum'
import { TouchableOpacity, TouchableWithoutFeedback } from 'react-native'
import getEstadosReservas from '../../utils/interfaces/getEstadosReservas'
import {
  ColumnDef,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import Filter from '../../utils/tools/filter'
import { baseApi } from '../../utils/api'
import { CSVLink } from 'react-csv'
import getEstadosReservas2 from '../../utils/interfaces/getEstadosReservas2'

function Reservas() {
  const navigate = useNavigate()
  const [reservas, setReservas] = useState([])
  const token = sessionStorage.getItem('token')
  let roles = [0]
  if (token) {
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
  }
  const [sorting, setSorting] = useState<SortingState>([])

  const columnHelper = createColumnHelper<any>()
  const columns: any[] = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        id: 'id',
        cell: (info) => <i>{info.getValue()}</i>,
        header: () => <span>ID</span>,
        footer: () => <span>ID</span>,
        enableColumnFilter: true,
        enableSorting: true,
        enableMultiSort: true,
        enablePinning: true,
      }),
      columnHelper.accessor('image', {
        cell: (info) => info.getValue(),
        header: () => <span>Reservado Por</span>,
        footer: () => <span>Reservado Por</span>,
      }),
      columnHelper.accessor((row) => row.created_at, {
        id: 'Fecha Solicitud',
        cell: (info) => <i>{info.getValue()}</i>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor((row) => row.date_start, {
        id: 'Fecha Inicio',
        cell: (info) => <i>{info.getValue()}</i>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor((row) => row.date_end, {
        id: 'Fecha Fin',
        cell: (info) => <i>{info.getValue()}</i>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor((row) => row.resources, {
        id: 'Recursos',
        cell: (info) => <i>{info.getValue()}</i>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor((row) => row.status, {
        id: 'Estado',
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor((row) => row.actions, {
        id: 'Acciones',
        cell: (info) => <i>{info.getValue()}</i>,
        footer: (info) => info.column.id,
      }),
    ],
    []
  )
  const [data, setData] = useState<any[]>([])
  const [data2, setData2] = useState<any[]>([])
  const csvData = [
    [
      'Id',
      'Reservado Por',
      'Fecha Solicitud',
      'Fecha Inicio',
      'Fecha Fin',
      'Recursos',
      'Estado',
    ],
    ...data2.map(
      ({
        id,
        reserved,
        created_at,
        date_start,
        date_end,
        resources,
        status,
      }: any) => [
        id,
        reserved,
        created_at,
        date_start,
        date_end,
        resources,
        getEstadosReservas2(status),
      ]
    ),
  ]
  const getReservas = async () => {
    setData([])
    let url = '/reservation/user'
    if (roles.includes(RolesEnum.Admin)) {
      url = '/reservation/all'
    }
    const { data: dataD } = await baseApi.get(`${API_URL}${url}`, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      },
    })
    dataD.map((res: any) => {
      data.push({
        id: res.id,
        image: (
          <HStack space={2}>
            <Avatar source={{ uri: res.user.image.url }} size={'sm'}>
              {res.user.username.charAt(0) + res.user.name.charAt(0)}
            </Avatar>
            <label className='form-label font-18  mb-0'>
              {res.user.username} {res.user.name}
            </label>
          </HStack>
        ),
        created_at: formatDate(new Date(res.created_at)),
        date_start: formatDate(new Date(res.start_date)),
        date_end: formatDate(new Date(res.end_date)),
        resources: res.transfers.length,
        status: res.status,
        actions: (
          <Button
            colorScheme={'warning'}
            onPress={() => {
              navigate(`/reservas/ver/${res.id}`)
            }}
            className='btn btn-sm btn-danger'
          >
            Ver
          </Button>
        ),
      })
      data2.push({
        id: res.id,
        reserved: `${res.user.username} ${res.user.name}`,
        created_at: formatDate(new Date(res.created_at)),
        date_start: formatDate(new Date(res.start_date)),
        date_end: formatDate(new Date(res.end_date)),
        resources: res.transfers.length,
        status: res.status,
      })
      return false
    })

    setData(data)
    // setReservas(res)
  }

  useEffect(() => {
    getReservas()
  }, [])
  return (
    <Wrapper title='Reservas' subtitle=''>
      {/* <!--start page wrapper --> */}
      <div className='page-wrapper' style={{ height: '100vh' }}>
        <div className='page-content'>
          <div className='row'>
            <div className='col-xl-12 mx-auto'>
              <h6 className='mb-0 text-uppercase'></h6>
              {roles.includes(RolesEnum.Docente) ? (
                <Box flexDir={'row'} justifyContent={'flex-end'}>
                  {/* <Input width={200} mr={4} placeholder={'Buscar...'}/> */}
                  <Button 
                    onPress={() => navigate('/reservas/crear')}
                    style={{marginRight: 10,
                      paddingHorizontal: 20,
                      paddingVertical: 10,
                      borderRadius: 8,
                      backgroundColor: '#0d6efd',
                      
                    }}	
                  >
                    Crear Nueva Reserva
                  </Button>
                </Box>
              ) : null}

              <hr />
              <div className='card'>
                <div className='card-body'>
                  <div className='align-content-end justify-content-end w-100'>
                    <div className='d-flex justify-content-end'>
                      <CSVLink
                        className='downloadbtn'
                        filename='reservas.csv'
                        data={csvData}
                      >
                        Exportar a CSV
                      </CSVLink>
                    </div>
                  </div>
                  {/* <table className='table table-responsive'>
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Reservado Por</th>
                        <th>Fecha Solicitud</th>
                        <th>Fecha Inicio</th>
                        <th>Fecha Fin</th>
                        <th>Recursos</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reservas.map((res: any) => (
                          <tr>
                            <td>{res.id}</td>
                            <td>
                              <HStack space={2}>
                                <Avatar source={{uri: res.user.image.url}} size={'sm'}>
                                  {res.user.username.charAt(0) + res.user.name.charAt(0)}
                                </Avatar>
                                <label className='form-label font-18  mb-0'>
                                  {res.user.username} {res.user.name}
                                </label>
                              </HStack>
                            </td>
														<td>{formatDate(new Date(res.created_at))}</td>
														<td>{formatDate(new Date(res.start_date))}</td>
														<td>{formatDate(new Date(res.end_date))}</td>
														<td>{res.transfers.length}</td>
														<td>
                                {getEstadosReservas(res.status)}
														</td>
                            <td>
																<Button  colorScheme={'warning'} onPress={()=> {
																	navigate(`/reservas/ver/${res.id}`)
																}} className="btn btn-sm btn-danger">Ver</Button>
															</td>
                          </tr>
                      ))}
                    </tbody>
                  </table> */}
                  <Table
                    columns={columns}
                    data={data}
                    refresh={null}
                    setSorting={setSorting}
                    sorting={sorting}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--end page wrapper --> */}
    </Wrapper>
  )
}
function Table({
  data,
  columns,
  refresh,
  sorting,
  setSorting,
}: {
  data: any[]
  columns: ColumnDef<any>[]
  refresh: any
  sorting: any
  setSorting: any
}) {
  let table = useReactTable({
    data: data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),

    //

    debugTable: false,
    autoResetAll: true,
  })
  let data2: any = []
  const navigate = useNavigate()
  return (
    <div className='p-2 bg-white table-responsive'>
      <table className='table  table-dark'>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                    {header.column.id !== 'Acciones' &&
                      header.column.id !== 'Fecha Inicio' &&
                      header.column.id !== 'Fecha Solicitud' &&
                      header.column.id !== 'Fecha Fin' && (
                        <>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </>
                      )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody className='table-light'>
          {table.getRowModel().rows.map((row) => {
            // if (!data2.includes(row.getValue('id'))) {
            //   data2 = [...data2, row.getValue('id')]
            // } else {
            //   return null
            // }
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} >
                      {cell.column.id === 'Estado' ? (
                        <>{getEstadosReservas(cell.getValue())}</>
                      ) : (
                        <>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </>
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className='h-2' />
      <div className='flex flex-column justify-content-end w-100 text-end'>
        <div className='text-end'>
          <button
            className='border rounded p-1'
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>{' '}
          <button
            className='border rounded p-1'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>{' '}
          <button
            className='border rounded p-1'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>{' '}
          <button
            className='border rounded p-1'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>{' '}
        </div>
        <br></br>
        <span className='flex items-center gap-1 '>
          {/* <div>Página</div> */}
          <strong>
            {table.getState().pagination.pageIndex + 1} de{' '}
            {table.getPageCount()}{' '}
          </strong>
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Mostrar {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div className='text-end'>{table.getRowModel().rows.length} Filas</div>
    </div>
  )
}
export default Reservas
