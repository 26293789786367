/* eslint-disable no-script-url */
import { Button, useTheme } from 'native-base'
import Wrapper from '../../components/Wrapper'
import jwtDecode from 'jwt-decode'
import { useEffect, useState } from 'react'
import SelectSearch from 'react-select-search'
import './react-style.css'
import { baseApi } from '../../utils/api'
import RolesEnum from '../../utils/interfaces/RolesEnum'
import { getFormatedDate } from '../Recursos/utils/getFormatedDate'
import { formatDate } from '../../utils/format'
import { useParams } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import { LogsResourcesEnum } from '../../utils/interfaces/LogsResourcesEnum'

function LogsResourcesOne() {
  const { colors } = useTheme()
  const [form, setForm] = useState({
    from: '',
    to: '',
  })
  const token = sessionStorage.getItem('token')
  let roles = [0]
  if (token) {
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
  }
  const [data, setData] = useState<any>([])
  const [data2, setData2] = useState<any>([])
  const params = useParams()
  const [setValue, setSetValue] = useState<any>('')
  const [options, setOptions] = useState<any>([])
  const csvData = [
    ['Id', 'Recurso', 'Almacén', 'Acción', 'Fecha', 'Usuario'],
    ...data.map(({ id, resource_name, depot, type, user, created_at }: any) => [
      id,
      resource_name,
      depot,
      type,
      created_at,
      `${user.username} ${user.lastname}`,
    ]),
  ]
  const getOptions = async () => {
    try {
      const url = roles.includes(RolesEnum.Admin)
        ? `/resource/all?`
        : `/user/get/resources?`
      let newData: any = []
      const { data } = await baseApi.get(`${url}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      newData.push({ name: 'TODOS', value: '0' })
      // eslint-disable-next-line array-callback-return
      data.map((v: any) => {
        newData.push({ name: v.name, value: v.id })
      })
      setOptions(newData)
    } catch (error) {
      console.log('🚀 ~ getOptions ~ error:', error)
    }
  }
  function downloadXLSX(base64: string) {
    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`
    const downloadLink = document.createElement('a')
    const fileName = 'exportLogs.xlsx'

    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }
  const exportLogs = async () => {
    try {
      const url = `/resource/export/logs`
      const url2 = `/resource/logs`
      // if (form.from === '' || form.to === '') {
      //   alert('Por favor seleccione un rango de fechas')
      //   return
      // }
      let paramss: any = {
        start: form.from,
        end: form.to,
      }
      if (setValue !== '0') {
        paramss['resource_id'] = setValue
      }
      const { data } = await baseApi.get(`${url}`, {
        params: paramss,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      const { data: date2 } = await baseApi.get(`${url2}/${params.id}`, {
        params: paramss,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      setData(date2)
      if (data.statusCode === 200) {
        setData2(data.data)
      }
    } catch (error) {
      console.log('🚀 ~ getOptions ~ error:', error)
    }
  }
  useEffect(() => {
    getOptions()
  }, [])

  return (
    <Wrapper title='Resumen'>
      {/* <!--start page wrapper --> */}
      <div className='page-wrapper'>
        <div className='page-content'>
          <div className='card'>
            <div className='card-body'>
            <h4 className='card-title text-primary fw-bold fs-5 text-primary'>
                Exportar Historial Recursos
              </h4>
              <div className='row gap-3 py-3'>
                <div className='col-12 col-md-5'>
                  <label className='fw-bold mb-1'>Fecha desde:</label>
                  <input
                    onChange={(value) => {
                      setForm({
                        ...form,
                        from: value.target.value,
                      })
                    }}
                    type='date'
                    className='form-control'
                    placeholder=''
                  />
                </div>
                <div className='col-12 col-md-5'>
                  <label className='fw-bold mb-1'>Fecha hasta:</label>
                  <input
                    onChange={(value) => {
                      setForm({
                        ...form,
                        to: value.target.value,
                      })
                    }}
                    type='date'
                    className='form-control'
                    placeholder=''
                  />
                </div>
                <div className='col-12 col-md-5'>
                  <label className='fw-bold mb-1'>Recurso:</label>
                  <SelectSearch
                    options={options}
                    search={true}
                    value={params.id}
                    defaultValue={'0'}
                    disabled
                    onChange={(value) => {
                      setSetValue(value)
                    }}
                    placeholder='Seleccionar recurso'
                  />
                </div>

                {/* <div className='col-md-3'>
                      <label className='fw-bold mb-1'>
                        Fecha de utilización:
                      </label>
                      <input onChange={(event: any)=>{
												setStartDate(event.target.value);
											}} className='form-control' type='date' />
                    </div>
                    <div className='col-md-3'>
                      <label className='fw-bold mb-1'>
                        Fecha de finalización:
                      </label>
                      <input onChange={(event: any)=>{
												setEndDate(event.target.value);
											}} className='form-control' type='date' />
                    </div> */}
                <div className='col-12 col-md-3'>
                  <label className='fw-bold mb-1'></label>
                  <Button onPress={() => exportLogs()}
                    style={{
                      backgroundColor: '#0d6efd',
                      borderRadius: 4,
                      padding: 4,
                      paddingLeft: 24,
                      paddingRight: 24,
                    }}
                    size={'md'}
                    _text={{
                      color: '#fff',
                      
                      fontSize: 16,
                      marginBottom: 1,
                    }}  
                  >Buscar</Button>
                </div>
              </div>
              <div>
                <div className='align-content-end justify-content-end w-100'>
                  <div className='d-flex justify-content-end'>
                    <CSVLink
                      className='downloadbtn'
                      filename='historial-recursos.csv'
                      data={csvData}
                    >
                      Exportar a CSV
                    </CSVLink>
                  </div>
                </div>
                <table className='table table-responsive'>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Recurso</th>
                      <th>Almacén</th>
                      <th>Acción</th>
                      <th>Fecha</th>
                      <th>Usuario</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((v: any) => (
                      <tr>
                        <td>{v.id}</td>
                        <td>{v.resource_name}</td>
                        <td>{v.depot}</td>
                        <td>{v.type}</td>
                        <td>{v.created_at}</td>
                        <td>
                          {v.user.username} {v.user.lastname}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--end page wrapper --> */}
    </Wrapper>
  )
}

export default LogsResourcesOne
