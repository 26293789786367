import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
  createCell,
  getSortedRowModel
} from '@tanstack/react-table'
import { Button, Modal, Select, useTheme } from 'native-base'
import { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import Wrapper from '../../components/Wrapper'
import { baseApi } from '../../utils/api'
import { API_URL } from '../../utils/env'
import { TouchableWithoutFeedback } from 'react-native'
import getStatusRecurso from './utils/getStatusRecurso'
import jwtDecode from 'jwt-decode'
import RolesEnum from '../../utils/interfaces/RolesEnum'

type Depot = {
  image: any
  id: string
  name: string
  family: string
  serie: string
  model: string
  location: string
  status: any
  reserved: any
}

function Recursos() {
  const { colors } = useTheme()
  const navigate = useNavigate()
  const [ loading, setLoading ] = useState(false)
  const [ refresh, setRefresh ] = useState(false)
  const [depotsData, setDepotsData] = useState<any>([])
  const [recursosList, setRecursosList] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [removeRecursoId, setRemoveRecursoId] = useState(0)
  const [search, setSearch] = useState('')
  const token = sessionStorage.getItem("token");
  let roles = [0];
  if(token){
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
  }
	const columnHelper = createColumnHelper<Depot>()
	const columns: any[] = useMemo(()=>[
			columnHelper.accessor('image', {
				cell: info => info.getValue(),
				header: () => <span></span>,
				footer: () => <span></span>,

			}),
			columnHelper.accessor(row => row.id, {
				id: 'id',
				cell: info => <i>{info.getValue()}</i>,
				header: () => <span>ID</span>,
				footer: () => <span>ID</span>,
			}),
			columnHelper.accessor(row => row.name, {
				id: 'Nombre',
				cell: info => <i>{info.getValue()}</i>,
				footer: info => info.column.id,
			}),
			columnHelper.accessor(row => row.family, {
				id: 'Familia',
				cell: info => <i>{info.getValue()}</i>,
				footer: info => info.column.id,
			}),
			columnHelper.accessor(row => row.serie, {
				id: 'Nº serie',
				cell: info => <i>{info.getValue()}</i>,
				footer: info => info.column.id,
			}),
			columnHelper.accessor(row => row.model, {
				id: 'Modelo',
				cell: info => <i>{info.getValue()}</i>,
				footer: info => info.column.id,
			}),
			columnHelper.accessor(row => row.location, {
				id: 'Ubicación',
				cell: info => <i>{info.getValue()}</i>,
				footer: info => info.column.id,
			}),
			columnHelper.accessor(row => row.status, {
				id: 'Estado',
				cell: info => <i>{info.getValue()}</i>,
				footer: info => info.column.id,
			}),
			columnHelper.accessor(row => row.reserved, {
				id: 'Reservado Por',
				cell: info => <i>{info.getValue()}</i>,
				footer: info => info.column.id,
			}),
		], [])
    const [ data, setData ] = useState<Depot[]>([])
    const [ depotSelected, setDepotSelected ] = useState<any>('0')
		// const data: Depot[] = [
		// 	{
		// 		id: '10',
		// 		image: <img src="https://i.imgur.com/7bIhcuT.png" width="40" style={{borderRadius: 20}} height="40" alt="asdas" />,
		// 		name: 'linsley',
		// 		family: 'vxvzx',
		// 		model: 'asdas',
		// 		location: 'In Relationship',
		// 		status: 'asda',
		// 		image_depot: <img src="https://i.imgur.com/7bIhcuT.png" style={{borderRadius: 20}} width="40" height="40" alt="asdas" />,
		// 	},
		// ]
    useEffect(() => {
          setData([])
    setLoading(true)
      getCentros()
    }, [search])
    const getDelegaciones = async () => {
    let urll = '/user/get/depots'

    if (roles.includes(RolesEnum.Admin)) {
      urll = `/depot/all`
    }
    try {
      const { data } = await baseApi.get(`${urll}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      setDepotsData(data)
    } catch (error) {
      if(__DEV__){
				console.log(error);
			}
    } finally {
    }
  }
  const getCentros = async () => {
    try {
      setData([])
      setRecursosList([])
    setLoading(true)
      
      const url = roles.includes(RolesEnum.Admin) ? `${API_URL}/resource/all?` :`${API_URL}/user/get/resources?`;
      const {data: dataD} = await baseApi.get(`${url}${search === '' ? '' : `search=${search}&`}${depotSelected === '0' ? '' : `depot_id=${depotSelected}`}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      setRecursosList(dataD)
      dataD.map((data2: any)=>{
      data.push({
          id: data2.id,
          image: <img src={data2.image ? data2.image.url : 'https://cms.ar-racking.com/uploads/2020/06/2023-3.jpg'} width="40" style={{borderRadius: 0}} height="30" alt="asdas" />,
          name: data2.name,
          serie: data2.serie,
          family: data2.fabricator,
          model: data2.model,
          location: data2.depot.name,
          status: getStatusRecurso(data2.status),
          reserved: data2.reservation ? `${data2.reservation.user.name} ${data2.reservation.user.username}` : '',
        })
      setData(data)
      setRefresh(!refresh)
      return false;
      })
      
    } catch (error) {
      if(__DEV__){
				console.log(error);
			}
    }
    setLoading(false)
  }
  useEffect(()=>{
    getDelegaciones()
  },[])
  useEffect(() => {
    getCentros()
  }, [token])
  function modalRemove() {
    return (
      <>
        <Modal
          style={{ height: '100%', zIndex: 99999 }}
          isOpen={modalVisible}
          onClose={() => setModalVisible(false)}
        >
          <Modal.Content style={{ zIndex: 99999 }}>
            <Modal.CloseButton />
            <Modal.Header>¿Desea eliminar el recurso?</Modal.Header>
            <Modal.Footer>
              <Button.Group space={2}>
                <Button
                  variant='ghost'
                  colorScheme='blueGray'
                  onPress={() => {
                    setModalVisible(false)
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  color={colors.red[500]}
                  onPress={() => {
                    removeRecursos(removeRecursoId)
                  }}
                >
                  Eliminar
                </Button>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </>
    )
  }
  const removeRecursos = (id: any) => {
    baseApi
      .delete(`${API_URL}/depot/delete/${id}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        // eslint-disable-next-line no-restricted-globals
        // location.reload();
      })
      .catch((error) => {
        if(__DEV__){
				console.log(error);
			}
      })
  }
  return (
    <Wrapper title='Recursos' subtitle=''>
      {modalRemove()}
      <div className='page-wrapper'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='row align-items-center'>
                    <div className='col-lg-6 col-xl-8'>
                      <div className='float-lg-end'>
                        <div className='row row-cols-lg-2 row-cols-xl-auto g-2'>
                          <div className='col'>
                            <div className='position-relative'>
                              <input
                                onChange={(e) => {
                                  setSearch(e.target.value)
                                  e.preventDefault()
                                }}
                                type='text'
                                className='form-control ps-5'
                                placeholder='Buscar...'
                                style={{ 
                                  borderRadius: 4,
                                  borderWidth: 2,
                                  borderColor: '#000000',
                                  padding: 8,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                  backgroundColor: '#fff2a7',
                                 }}
                              />{' '}
                              <span className='position-absolute top-50 product-show translate-middle-y'>
                                <i className='bx bx-search'></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className='col-lg-3 col-xl-2'>
                      <Button onPress={() => navigate('/almacenes/crear')}>
                        Informes
                      </Button>
                    </div> */}
                    {/* <div className='col-lg-3 col-xl-2'>
                      <Button onPress={() => navigate('/logsresources')}>
                        Exportador de Logs
                      </Button>
                    </div> */}
                    <div className='col-lg-3 col-xl-2'>
                      <Button onPress={() => navigate('/buscadoravanzado')}
                         style={{
                          backgroundColor: '#198754',
                          borderRadius: 4,
                          padding: 8,
                          paddingLeft: 32,
                          paddingRight: 32,
                        }}
                        size={'md'}
                        _text={{
                          color: '#fff',
                          fontWeight: 'bold',
                          fontSize: 16,
                          marginBottom: 1,
                        }}
                      >
                        Buscador Avanzado
                      </Button>
                    </div>
                    <div className='col-lg-3 col-xl-2'>
                      <Button onPress={() => navigate('/recursos/crear')}
                         style={{
                          backgroundColor: '#0d6efd',
                          borderRadius: 4,
                          padding: 8,
                          paddingLeft: 32,
                          paddingRight: 32,
                        }}
                        size={'md'}
                        _text={{
                          color: '#fff',
                          fontWeight: 'bold',
                          fontSize: 16,
                          marginBottom: 1,
                        }}  
                      >
                        Crear
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row row-cols-1 row-cols-sm-2 row-cols-lg-4 row-cols-xl-3 row-cols-xxl-3 product-grid bg-white'></div>
          <div>
            {!loading && (
              <Table
                {...{
                  data,
                  columns,
                  refresh,
                }}
              />
            )}
          </div>

          {/* <!--end row--> */}
        </div>
      </div>
    </Wrapper>
  )
}
function Table({
  data,
  columns,
  refresh,
}: {
  data: Depot[]
  columns: ColumnDef<Depot>[],
  refresh: any
}) {
  let table = useReactTable({
  data: data,
    columns,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    //
            getSortedRowModel: getSortedRowModel(),

    debugTable: false,
    autoResetAll: true,
    
  })
  let data2: any = []
  const navigate = useNavigate()
  return (
    <div className="p-2 bg-white table-responsive">
      <table className='table  table-dark'>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div {...{onClick: header.column.getToggleSortingHandler(),}}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? null}
                        {header.column.getCanFilter() ? (
                          <div>
                            {/* <Filter column={header.column} table={table} /> */}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody className='table-light'>
          {table.getRowModel().rows.map(row => {
                  if(!data2.includes(row.getValue('id'))){
                    data2 = [...data2, row.getValue('id')]
                  }else{
                    return null;
                  }
            return (
              <tr key={row.id}>
                
                {row.getVisibleCells().map(cell => {
               
                  return (
                    <TouchableWithoutFeedback onPress={()=> navigate(`/recursos/ver/${cell.row.original.id}`)}>
                      <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                    </TouchableWithoutFeedback>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="h-2" />
      <div className="flex flex-column justify-content-end w-100 text-end">
        <div className='text-end'>
					<button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>{' '}
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>{' '}
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>{' '}
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>{' '}
				</div>
				<br></br>
        <span className="flex items-center gap-1 ">
          {/* <div>Página</div> */}
          <strong>
            {table.getState().pagination.pageIndex + 1} de{' '}
            {table.getPageCount()}{' '}
          </strong>
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Mostrar {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div className='text-end'>{table.getRowModel().rows.length} Filas</div>
    </div>
  )
}

export default Recursos
