/* eslint-disable no-script-url */
import { Box } from "native-base"
import Header from "./Header/Header";
import MenuLeft from "./MenuLeft";

function Wrapper({ children, hasButtonRight, title, subtitle }: { hasButtonRight?: boolean, title?: string, subtitle?: string, children: React.ReactNode }) {
	return (
		<>
			<div className="wrapper">
				<MenuLeft />
				<Header hasButtonRight={hasButtonRight} title={title} subtitle={subtitle} />
				{children}
				<footer className="page-footer">
					<p className="mb-0">Copyright © {new Date().getFullYear()}. All right reserved.</p>
				</footer>
				<div className="overlay toggle-icon"></div>
				<a href="#!" className="back-to-top">
					<i className="bx bxs-up-arrow-alt"></i>
				</a>
			</div>
		</>
	);
}
export default Wrapper;
