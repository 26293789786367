import axios from "axios";
import {
	Text,
	Input,
	Select,
	Modal as Modals,
	FormControl,
	ScrollView,
} from "native-base";
import { Avatar, Box, Button, HStack, VStack } from "native-base";
import { useEffect, useRef, useState } from "react";
import Wrapper from "../../components/Wrapper";
import { API_URL } from "../../utils/env";
import { useNavigate } from "react-router-dom";
import { baseApi } from "../../utils/api";
import { TouchableOpacity } from "react-native";
import { UsuariosModal } from "../../components/UsuariosModal/UsuariosModal"
import Modal from "../../components/Modal/Modal"

function CrearCentros() {
	const [form, setForm] = useState({
		name: "",
		phone: "",
		address: "",
		delegation_id: "5",
		province_id: "",
	});
	const [provinces, setProvinces] = useState([]);
	const [delegations, setDelegations] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [image, setImage] = useState<any>(null);
	const [userList, setUserList] = useState<any>([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [modalType, setModalType] = useState("");

	const [usuarios, setUsuarios] = useState<any>({
		coord_centro: [],
		docentes: [],
		usuarios_almacen: [],
	});
	const initialRef = useRef(null);
	const finalRef = useRef(null);
	const navigate = useNavigate();
	const getUsuarios = async () => {
		try {
			const { data } = await baseApi.get(`${API_URL}/user/all`);
			setUserList(data);
		} catch (error) {
			if(__DEV__){
				console.log(error);
			}
		}
	};
	const getProvinces = async () => {
		setIsLoading(true);

		try {
			const { data } = await baseApi.get(`${API_URL}/province/all`);
			setProvinces(data);
		} catch (error) {
			if(__DEV__){
				console.log(error);
			}
		}
		setIsLoading(false);
	};
	const getDelegations = async () => {
		setIsLoading(true);

		try {
			const { data } = await baseApi.get(`${API_URL}/delegation/all`,{
				headers: {
					Authorization: "Bearer " + sessionStorage.getItem("token"),
				},
			});
			setDelegations(data);
		} catch (error) {
			if(__DEV__){
				console.log(error);
			}
		}
		setIsLoading(false);
	};
	useEffect(() => {
		getProvinces();
		getDelegations();
		getUsuarios();
	}, []);
	const setUserRole = async (user: any, role: string) => {
		setIsLoading(true);
		try {
			switch (role) {
				case "coord_centro":
					if(usuarios.coord_centro.findIndex((u: any) => u.id === user.id) === -1){
						setUsuarios({
							...usuarios,
							coord_centro: [...usuarios.coord_centro, user],
						});
					}
					
					break;
				case "docentes":
					if(usuarios.docentes.findIndex((u: any) => u.id === user.id) === -1){
						setUsuarios({
							...usuarios,
							docentes: [...usuarios.docentes, user],
						});
					}
					break;
			}
			setModalVisible(false);
		} catch (error) {
			if(__DEV__){
				console.log(error);
			}
		}
		setIsLoading(false);
	};
	function modalUsuarios() {
		return (
			<>
				<Modals
					isOpen={modalVisible}
					onClose={() => setModalVisible(false)}
					initialFocusRef={initialRef}
					finalFocusRef={finalRef}
				>
					<Modals.Content>
						<Modals.CloseButton />
						<Modals.Header>Seleccione Usuario</Modals.Header>
						<Modals.Body>
							<ScrollView style={{ maxHeight: "500px" }}>
								{userList.map((user: any) => {
									if (user.id === 1) return false;
									return (
										<TouchableOpacity
											onPress={() => {
												setUserRole(user, modalType);
											}}
										>
											<HStack
												mb={2}
												backgroundColor={"#F5F7FA"}
												px={1}
												borderRadius={8}
												borderColor={"primary.500"}
												borderWidth={1}
											>
												<HStack
													width={"100%"}
													my={2}
													alignItems="center"
													space={3}
												>
													<Avatar
														size={"sm"}
														bg="green.500"
														source={{
															uri: user.image.url,
														}}
													>
														USER
													</Avatar>
													<Text
														width={"100%"}
														fontSize={18}
														color="coolGray.600"
														_dark={{
															color: "warmGray.200",
														}}
													>
														{user.name}
													</Text>
												</HStack>
											</HStack>
										</TouchableOpacity>
									);
								})}
							</ScrollView>
						</Modals.Body>
						{/* <Modal.Footer>
							<Button.Group space={2}>
								<Button
									variant="ghost"
									colorScheme="blueGray"
									onPress={() => {
										setModalVisible(false);
									}}
								>
									Cancelar
								</Button>
								<Button
									onPress={() => {
										setModalVisible(false);
									}}
								>
									Seleccionar
								</Button>
							</Button.Group>
						</Modal.Footer> */}
					</Modals.Content>
				</Modals>
			</>
		);
	}

	const createDelegacion = async () => {
		try {
			if(form.name === '' || form.address === '' || form.province_id === ''){
				alert("Los campos nombre, dirección y provincia son obligatorios (Nombre, Dirección, Provincia)");
				return false;
			}
			setIsLoading(true);
			var newDelegacion = new FormData();
			newDelegacion.append("name", form.name);
			newDelegacion.append("phone", form.phone);
			newDelegacion.append("address", form.address);
			newDelegacion.append("province_id", form.province_id);
			newDelegacion.append("delegation_id", "5");
			if(image !== null){
				newDelegacion.append("image", image!, image ? image.name : "");
			}

			const { data } = await baseApi.post(
				`${API_URL}/center/create`,
				newDelegacion,
				{
					headers: {
						"Content-Type": "multipart/form-data",

						Authorization: "Bearer " + sessionStorage.getItem("token"),
					},
				}
			);
			const newIdCentro = data.id;
			usuarios.coord_centro.map(async (u: any) => {
				await baseApi.post(
					`${API_URL}/user/add/center`,
					{
						"user_id": u.id,
						"center_id": newIdCentro,
						"role_id": 3
					},
					{
						headers: {	
							Authorization: "Bearer " + sessionStorage.getItem("token"),
						},
					}
				);
			});
			usuarios.docentes.map(async (u: any) => {
				await baseApi.post(
					`${API_URL}/user/add/center`,
					{
						"user_id": u.id,
						"center_id": newIdCentro,
						"role_id": 4
					},
					{
						headers: {	
							Authorization: "Bearer " + sessionStorage.getItem("token"),
						},
					}
				);
			});
			navigate("/centros");
		} catch (error) {
			if(__DEV__){
				console.log(error);
			}
		}
		setIsLoading(false);
	};
	return (
		<Wrapper title="Crear Centros" subtitle="">
			{/* {modalUsuarios()} */}
			<UsuariosModal modalVisible={modalVisible} setModalVisible={setModalVisible} setUserRole={setUserRole} modalType={modalType} finalRef={finalRef} initialRef={initialRef}/>
			
			<div className="page-wrapper">
				<div className="page-content">
					<div className="row">
						<div className="col-xl-12 mx-auto">
							<hr />
							<div className="card">
								<div className="card-body">
									<form className="row g-3">
										<div className="col-md-4 mb-3">
											<label className="form-label">Centro de Formación:</label>
											<Input
												value={form.name}
												onChangeText={(value) =>
													setForm({ ...form, name: value })
												}
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-4 mb-3">
											<label className="form-label">Telefono:</label>
											<Input
												value={form.phone}
												onChangeText={(value) =>
													setForm({ ...form, phone: value })
												}
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-4 mb-3">
											<label htmlFor="provincia" className="form-label">
												Provincia
											</label>
											<Select
												nativeID="provincia"
												onValueChange={(value) =>
													setForm({ ...form, province_id: value })
												}
												selectedValue={form.province_id.toString()}
											>
												<Select.Item value="" label="Escoger..." />
												{provinces.map((province: any) => (
													<Select.Item
														key={province.id}
														value={province.id.toString()}
														label={province.name}
													/>
												))}
											</Select>
										</div>
										<div className="col-md-12 mb-3">
											<label className="form-label">Dirección:</label>
											<Input
												value={form.address}
												onChangeText={(value) =>
													setForm({ ...form, address: value })
												}
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-12 mb-3">
											<label className="form-label">Foto del Centro:</label>

											<input
												onChange={(event: any) =>
													setImage(event.target.files[0])
												}
												type="file"
												className="form-control"
												placeholder=""
											/>
										</div>
										{/* <div className="col-md-6 mb-3">
											<label htmlFor="delegations" className="form-label">
												Delegación:
											</label>
											<Select
												nativeID="delegations"
												onValueChange={(value) =>
													setForm({ ...form, delegation_id: value })
												}
												selectedValue={form.delegation_id.toString()}
											>
												<Select.Item value="" label="Escoger..." />
												{delegations.map((province: any) => (
													<Select.Item
														key={province.id}
														value={province.id.toString()}
														label={province.name}
													/>
												))}
											</Select>
										</div> */}
										{/* <div className="col-md-6 mb-3">
											<label htmlFor="estado" className="form-label">
												Almacen a Escoger
											</label>
											<select id="estado" className="form-select">
												<option selected={false}>Escoger...</option>
												<option>Activo</option>
												<option>No Activo</option>
											</select>
										</div> */}
										<div className="col-md-6 mb-3">
											<HStack space={2} justifyContent={"space-between"} alignItems={"center"}>
												
													<h5 className="form-label m-0 fw-bold fs-6 text-primary">
														Coordinador del centro
													</h5>
													<Button
														onPress={() => {
															setModalVisible(true);
															setModalType("coord_centro");
														}}
														justifyContent={"flex-end"}
														variant={"ghost"}
													>
														<i className="bx font-20 bx-plus-circle"></i>
													</Button>
											</HStack>

											<VStack width={"100%"}>
												{usuarios.coord_centro.map((user: any) => (
													<HStack
														mb={2}
														backgroundColor={"#F5F7FA"}
														px={4}
														borderRadius={8}
														borderColor={"primary.500"}
														borderWidth={1}
													>
														<HStack
															width={"100%"}
															my={2}
															alignItems="center"
															space={3}
														>
															<Avatar
																size={"sm"}
																bg="green.500"
																source={{
																	uri: user.image.url,
																}}
															>
																{user.name.charAt(0)}
															</Avatar>
															<Text
																width={"100%"}
																fontSize={18}
																color="coolGray.600"
																_dark={{
																	color: "warmGray.200",
																}}
															>
																{user.name}
															</Text>
															<Button onPress={()=> {
																setUsuarios({
																	...usuarios,
																	coord_centro: usuarios.coord_centro.filter((u: any) => u.id !== user.id)
																})
															}} variant={"ghost"}>
																<i className="bx bxs-trash"></i>
															</Button>
														</HStack>
													</HStack>
												))}
											</VStack>
										</div>
										<div className="col-md-6 mb-3">
											<HStack space={2} justifyContent={"space-between"} alignItems={"center"}>
												<h5 className="form-label fw-bold fs-6 text-primary">Docente</h5>
												<Button
													onPress={() => {
														setModalVisible(true);
														setModalType("docentes");
													}}
													justifyContent={"flex-end"}
													variant={"ghost"}
												>
													<i className="bx font-20 bx-plus-circle"></i>
												</Button>
											</HStack>

											<VStack width={"100%"}>
											{usuarios.docentes.map((user: any) => (
													<HStack
														mb={2}
														backgroundColor={"#F5F7FA"}
														px={1}
														borderRadius={8}
														borderColor={"primary.500"}
														borderWidth={1}
													>
														<HStack
															width={"100%"}
															my={2}
															alignItems="center"
															space={3}
														>
															<Avatar
																size={"sm"}
																bg="green.500"
																source={{
																	uri: user.image.url,
																}}
															>
																{user.name.charAt(0)}
															</Avatar>
															<Text
																width={"100%"}
																fontSize={18}
																color="coolGray.600"
																_dark={{
																	color: "warmGray.200",
																}}
															>
																{user.name}
															</Text>
															<Button onPress={()=> {
																setUsuarios({
																	...usuarios,
																	docentes: usuarios.docentes.filter((u: any) => u.id !== user.id)
																})
															}} variant={"ghost"}>
																<i className="bx bxs-trash"></i>
															</Button>
														</HStack>
													</HStack>
												))}
											</VStack>
										</div>
										<div className="col-md-12 mb-3 justify-content-center d-flex">
											<Button
												isLoading={isLoading}
												onPress={() => createDelegacion()}

												style={{
													backgroundColor: '#0d6efd',
													borderRadius: 4,
													padding: 8,
													paddingLeft: 32,
													paddingRight: 32,
													width: '50%',
												  }}
												  size={'md'}
												  _text={{
													color: '#fff',
													fontWeight: 'bold',
													fontSize: 16,
													marginBottom: 1,
												  }}
											>
												Crear Centro
											</Button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
}
export default CrearCentros;
