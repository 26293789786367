import CheckIcon from '../../../components/Icons/CheckIcon'
import IncidenciaIcon from '../../../components/Icons/IncidenciaIcon'
import ReservaTras from '../../../components/Icons/ReservaTras'
import TrasladoIcon from '../../../components/Icons/TrasladoIcon'
import Incidencias from '../Incidencias'

const TagStatus = ({ status, icon, color , style }: { status: string, icon:string, color: string, style?:string}) => {
  return (
    <div className={`bg-${color} col-12 d-flex justify-content-center align-items-center rounded-2`}
      style={(style) ? {
        backgroundColor: style 
      } : {}}
    >
      <i className={`bx ${icon} bx-sm text-white mx-2`}></i>
      <span className='form-label font-6 text-success fw-bold text-white my-2 '>
        {status}
      </span>
    </div>
  )
}

const getStatusRecurso = (status: number) => {
  switch (status) {
    case 1:
      return (
        <TagStatus status='Disponible' icon='bx-check' color='success' />
      )
    case 2:
      return (
        <TagStatus status='Reservada' icon='bx-cart' color='info' />
      );
    case 3:
      return (
        // Incidencias
        <TagStatus status='Incidencia' icon='bx-error' color='danger' />
      );
    case 4:
      return (
        // manteniemiento
        <TagStatus status='Mantenimiento' icon='bx-wrench' color='warning' />
      );
    case 5:
      return (
        // En traslado
        <TagStatus status='En Traslado' icon='bx-transfer' color='primary' />
      );
    case 6:
      return (
        // Confirmación envio
        <TagStatus status='Confirmación de envio' icon='bx-square' color='teal' style='teal' />
      );
    case 7:
      // Pendiente Recepción
      return (
        <TagStatus status='Pendiente Recepción' icon='bx-alarm-exclamation' color='secondary' style='pink' />
      );
    case 11:
      return (
              // Pendiente de Revisión
        <TagStatus status='Pendiente de Revisión' icon='bx-hourglass' color='secondary' />
      );
    case 8:
      return (
              // Reservada Solicitud de envio
        <TagStatus status='Reservada Solicitud de envio' icon='bx-notepad' color='secondary' />
      );
    case 9:
      return (
              // Dado de Baja
        <TagStatus status='Dado de Baja' icon='bx-x' color='secondary' />
      );
    case 10:
      return (
              // En Traslado con Reserva
        <TagStatus status='En Traslado con Reserva' icon='bx-bus' color='secondary' />
      );
    default:
      return (
        <TagStatus status='Disponible' icon='bx-check' color='secondary' />
      )
  }
}

export default getStatusRecurso
