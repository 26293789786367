import jwtDecode from 'jwt-decode'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import Almacenes from './screens/Almacenes/Almacenes'
import CrearAlmacen from './screens/Almacenes/CrearAlmacen'
import Centros from './screens/Centros/Centros'
import CrearCentros from './screens/Centros/CrearCentros'
import EditarCentros from './screens/Centros/EditarCentros'
import Home from './screens/Home/Home'
import Login from './screens/Login/Login'
import CrearRecursos from './screens/Recursos/CrearRecursos'
import Recursos from './screens/Recursos/Recursos'
import VerQR from './screens/Recursos/VerQR'
import VerRecurso from './screens/Recursos/VerRecurso'
import CrearUsuario from './screens/Usuarios/CrearUsuario'
import Usuarios from './screens/Usuarios/Usuarios'
import RolesEnum from './utils/interfaces/RolesEnum'
import EditarAlmacen from './screens/Almacenes/EditarAlmacen'
import EditarRecursos from './screens/Recursos/EditarRecursos'
import EditarUsuario from './screens/Usuarios/EditarUsuario'
import Configurador from './screens/Configurador/Configurador'
import Reservas from './screens/Reservas/Reservas'
import CrearReserva from './screens/Reservas/CrearReserva'
import CestaReserva from './screens/Reservas/CestaReserva'
import VerReserva from './screens/Reservas/VerReserva'
import BuscadorAvanzado from './screens/Recursos/BuscadorAvanzado'
import ImprimirQr from './screens/Recursos/ImprimirQr'
import Incidencias from './screens/Recursos/Incidencias'
import VerIncidencias from './screens/Recursos/VerIncidencias'
import SubirRecursos from './screens/SubirRecursos/SubirRecursos'
import HomeCoord from './screens/HomeCoord/HomeCoord'
import HomeResp from './screens/HomeResp/HomeResp'
import ForgotPassword from './screens/ForgotPassword/ForgotPassword'
import CheckCodeForgot from './screens/ForgotPassword/CheckCodeForgot'
import UpdatePassword from './screens/ForgotPassword/UpdatePassword'
import EditarPerfilUsuario from './screens/Usuarios/EditarPerfilUsuario'
import CrearIncidencias from './screens/Recursos/CrearIncidencias'
import LogsResources from './screens/LogsResources/LogsResources'
import LogsIncidencias from './screens/LogsIncidencias/LogsIncidencias'
import LogsResourcesOne from './screens/LogsResourcesOne/LogsResources'

function App() {
  const token = sessionStorage.getItem('token')
  let roles = [0]
  let home = <Home />
  if (token) {
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
    if (roles.includes(RolesEnum.CoordinadorCentro)) {
      home = <HomeCoord />
    }
    if (roles.includes(RolesEnum.ResponsableAlmacen)) {
      home = <HomeResp />
    }
    if (roles.includes(RolesEnum.Docente)) {
      home = <Reservas />
    }
  }
  return (
    <Router>
      <Routes>
        <Route path='/recursos/ver'>
          <Route path=':id' element={<VerRecurso />} />
        </Route>
        <Route path='/incidencias'>
          <Route path=':id' element={<Incidencias />} />
        </Route>
        <Route path='/incidencias/ver'>
          <Route path=':id/:resource' element={<VerIncidencias />} />
        </Route>
        <Route path='/crearincidencias'>
          <Route path=':id' element={<CrearIncidencias />} />
        </Route>
        <Route path='/updatepassword' element={<UpdatePassword />} />
        <Route path='/editarmisusario' element={<EditarPerfilUsuario />} />
        <Route path='/checkcodeforgot' element={<CheckCodeForgot />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/logsrecursos/exportar'>
          <Route path=':id' element={<LogsResourcesOne />} />
        </Route>
        <Route path='/buscadoravanzado' element={<BuscadorAvanzado />} />
        <Route path='/imprimirqr'>
          <Route path=':id/:qr' element={<ImprimirQr />} />
        </Route>
        {!token ? (
          <Route caseSensitive path='/' element={<Login />} />
        ) : (
          <>
            <Route path='/' element={home} />
            {roles.includes(RolesEnum.ResponsableAlmacen) ? (
              <>
                <Route path='/' element={<HomeResp />} />
                <Route path='/subirrecursos' element={<SubirRecursos />} />
              </>
            ) : null}
            {roles.includes(RolesEnum.CoordinadorCentro) ? (
              <>
                <Route path='/' element={<HomeCoord />} />
                <Route path='/usuarios' element={<Usuarios />} />
                <Route path='/usuarios/crear' element={<CrearUsuario />} />
                <Route path='/reservas' element={<Reservas />} />
                <Route path='/centros' element={<Centros />} />
                <Route path='/reservas/ver'>
                  <Route path=':id' element={<VerReserva />} />
                </Route>
                <Route path='/centros/editar'>
                  <Route path=':id' element={<EditarCentros />} />
                </Route>
              </>
            ) : null}
            {roles.includes(RolesEnum.Docente) ? (
              <>
                <Route path='/reservas' element={<Reservas />} />
                <Route path='/cesta' element={<CestaReserva />} />
                <Route path='/reservas/crear' element={<CrearReserva />} />
                <Route path='/reservas/ver'>
                  <Route path=':id' element={<VerReserva />} />
                </Route>
              </>
            ) : null}
            {roles.includes(RolesEnum.Admin) ? (
              <>
                <Route path='/configurador' element={<Configurador />} />
                <Route path='/logsresources' element={<LogsResources />} />
                <Route path='/subirrecursos' element={<SubirRecursos />} />
                <Route path='/usuarios' element={<Usuarios />} />
                <Route path='/usuarios/crear' element={<CrearUsuario />} />
                {/* <Route path="/delegaciones" element={<Delegaciones />}/>
              <Route path="/delegaciones/crear" element={<CrearDelegaciones />}/> */}
                <Route path='/centros' element={<Centros />} />
                <Route path='/centros/editar'>
                  <Route path=':id' element={<EditarCentros />} />
                </Route>
                <Route path='/logsincidencias/exportar'>
                  <Route path=':id' element={<LogsIncidencias />} />
                </Route>
                <Route path='/centros/crear' element={<CrearCentros />} />
                <Route path='/almacenes/crear' element={<CrearAlmacen />} />
                <Route path='/almacenes/editar'>
                  <Route path=':id' element={<EditarAlmacen />} />
                </Route>
                <Route path='/usuarios/editar'>
                  <Route path=':id' element={<EditarUsuario />} />
                </Route>
                <Route path='/reservas' element={<Reservas />} />

                <Route path='/reservas/ver'>
                  <Route path=':id' element={<VerReserva />} />
                </Route>
              </>
            ) : null}
            {roles.includes(RolesEnum.ResponsableAlmacen) ||
            roles.includes(RolesEnum.Admin) ||
            roles.includes(RolesEnum.CoordinadorCentro) ? (
              <>
                <Route path='/almacenes' element={<Almacenes />} />
                <Route path='/recursos' element={<Recursos />} />
                <Route path='/recursos/ver/qr/:qr' element={<VerQR />} />
                <Route path='/recursos/editar'>
                  <Route path=':id' element={<EditarRecursos />} />
                </Route>
                <Route path='/recursos/crear' element={<CrearRecursos />} />
              </>
            ) : null}
          </>
        )}
      </Routes>
    </Router>
  )
}

export default App
